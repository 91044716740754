/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.snow.css';

html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, sans-serif;
}

*, p, h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
}

*:focus {
    outline: none;
}

input{
    padding-left: 15px;
}

.mat-table {
    width: 100%;
}

.row{
    background: #FFF;
}